<template>
  <vf-search v-bind="forwarded" />
</template>

<script lang="ts" setup>
import { useForwardPropsEmits } from 'radix-vue'
import VfSearch, { type Emits, type Props } from '#commerce/components/vf/search/Search.vue'

const props = defineProps<Props>()
const emits = defineEmits<Emits>()
const { $feature } = useNuxtApp()
const forwarded = useForwardPropsEmits(props, emits)

const appConfig = useAppConfig().components.vf.search

if ($feature.tempHeaderVariation === 'basic')
  appConfig.brandClasses.helpfulLinks = '<lg:hidden'

if ($feature.tempHeaderVariation === 'elevated') {
  appConfig.brandClasses.suggestions.products = 'block space-y-2'
  appConfig.showHelpfulLinks = false
}
</script>

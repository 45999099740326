<template>
  <base-toggle v-model="model">
    <svg
      class="vf-oaxla0"
      :height="sizes[size]"
      viewBox="0 0 10 10"
      :width="sizes[size]"
    >
      <circle :class="{ 'op-0': model }" cx="4.5" cy="4.5" :r="circleRadius" />
      <path v-for="(animation, i) in paths" :key="i" :d="animation[0]">
        <animate
          ref="refs"
          v-bind="{
            attributeName: 'd',
            begin: 'indefinite',
            dur: '200ms',
            fill: 'freeze',
            values: getSvgAnimateValues(animation, !model),
          }"
        />
      </path>
    </svg>
    <slot />
  </base-toggle>
</template>

<script lang="ts" setup>
import type { IconSizes } from '#types/sizes'

withDefaults(defineProps<{ size?: IconSizes }>(), { size: 'lg' })

const { size: sizes } = useAppConfig().components.vf.icon
const { circleRadius, paths } = useAppConfig().components.icon.search

const model = defineModel<boolean>()
const refs = ref<SVGAnimateElement[]>([])

watch(model, () => refs.value.forEach((el) => el.beginElement()))
</script>
